<template>
  <b-row class="mb-32">
    <b-col cols="12" class="mb-32">
      <b-row align-v="center" align-h="between">
      </b-row>
    </b-col>

    <b-col cols="12">
      <b-row>
        <b-col cols="12" md="6" xl="6" class="mb-32">
          <!-- <customer-feature-card /> -->
          <router-link :to="{ path: '/list/theft/vehicle' }" class="text-decoration-none">
          <b-card>
              <b-row>
                <b-col class="hp-flex-none w-auto">
                  <b-avatar class="bg-secondary-4 hp-bg-color-dark-secondary" size="48px">
      
                    <i>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor"
                          viewBox="0 0 16 16" class="bi bi-car-front-fill" style="color: black;">
                          <path
                            d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z">
                          </path>
                        </svg>
                      </i>
                  </b-avatar>
                </b-col>

                <b-col class="pl-0">
                <h3 class="mb-4 mt-8">
                  {{ totalTheftVehicles }}
                </h3>

                <p class="hp-p1-body mb-0 text-black-80 hp-text-color-dark-30">
                  Total Theft Vehicle
                </p>
                 </b-col>
                </b-row>
            </b-card>
          </router-link>
        </b-col>

        <b-col cols="12" md="6" xl="6" class="mb-32">
          <router-link :to="{ path: '/recover/list/police/vehicle' }" class="text-decoration-none">
          <b-card>
              <b-row>
                <b-col class="hp-flex-none w-auto">
                  <b-avatar class="bg-secondary-4 hp-bg-color-dark-secondary" size="48px">

                    <i>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor"
                          viewBox="0 0 16 16" class="bi bi-car-front-fill" style="color: black;">
                          <path
                            d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z">
                          </path>
                        </svg>
                      </i>
                  </b-avatar>
                </b-col>

                <b-col class="pl-0">
                <h3 class="mb-4 mt-8">
                  {{ totalRecoverVehicles }}
                </h3>

                <p class="hp-p1-body mb-0 text-black-80 hp-text-color-dark-30">
                  Total Recover Vehicle
                </p>
                 </b-col>
                </b-row>
            </b-card>
          </router-link>
        </b-col>
        <!-- .... -->

        <b-col cols="12" xl="12">
          <b-row>
            <b-col cols="12" class="mb-32">
              <revenue-column-card />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard ,BAvatar} from "bootstrap-vue";
import axios from "axios";
import CustomerFeatureCard from "../../widgets/cards/advance/CustomerFeatureCard.vue";
import OrdersFeatureCard from "../../widgets/cards/advance/OrdersFeatureCard.vue";
import SaleFeatureCard from "../../widgets/cards/advance/SaleFeatureCard.vue";
import ProfitFeatureCard from "../../widgets/cards/advance/ProfitFeatureCard.vue";
import RevenueColumnCard from "../../widgets/cards/analytics/RevenueColumnCard.vue";
import CtaCardTwo from "../../widgets/cards/advance/CtaCardTwo.vue";
import RevenueCard from "../../widgets/cards/statistics/RevenueCard.vue";
import BrowserStates from "./BrowserStates.vue";
import EarningsCard from "./EarningsCard.vue";
import LatestOrders from "./LatestOrders.vue";
import CheckDetailCard from "./CheckDetailCard.vue";
import BestSellingProducts from "./BestSellingProducts.vue";

export default {
  data() {
    return {
      totalTheftVehicles: 0,
      totalRecoverVehicles: 0,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    CustomerFeatureCard,
    OrdersFeatureCard,
    SaleFeatureCard,
    ProfitFeatureCard,
    RevenueColumnCard,
    CtaCardTwo,
    RevenueCard,
    BrowserStates,
    EarningsCard,
    LatestOrders,
    CheckDetailCard,
    BestSellingProducts,
  },
  mounted() {
    this.getDashboardData();
  },
  methods: {
    async getDashboardData() {
      try {
        const response = await axios.get("/dashboard");
        if (response.data.success) {
          this.totalTheftVehicles = response.data.data.total_theft_vehicles;
          this.totalRecoverVehicles = response.data.data.total_recover_vehicles;
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    },
  },
};
</script>
